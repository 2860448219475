import React from 'react';
import styled from 'styled-components';
import { SubTitle, Title } from '../../styles';

const IntroContainer = styled.div`
  position: relative;
  height: 100vh;
  width: 100vw;
`;

const IntroWrapper = styled.div`
  padding-top: 8vh;
  height: 55vh;
  display: flex;
  color: white;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const IntroVideo = styled.video`
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
`;

const Intro: React.FC = () => (
  <IntroContainer>
    <IntroWrapper>
      <Title>Market and Risk Intelligence for Digital Assets</Title>
      <SubTitle>
        An end-to-end research and risk management solution for
        institutional investors
      </SubTitle>
      <IntroVideo width="100%" autoPlay muted loop>
        <source src="/assets/uptick_background.mp4" type="video/mp4" />
      </IntroVideo>
    </IntroWrapper>
  </IntroContainer>
);

export default Intro;
