import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { RootState } from './store';

import { logout, refreshToken } from './account/actions';
import { UnauthView } from './ui';

const refreshTokenInterval = 30 * 1000;

const authRoutes = ['/', '/signup', '/signin', '/oauth-callback'];
const tier1Routes = ['/account', '/dashboard', '/news', '/screener', '/alerts', '/profile', '/events'];
const tier2Routes = tier1Routes.concat(['/portfolio', '/gpt']);

const withAuth = (Component: any) => {
  const Auth = (props: any) => {
    const router = useRouter();
    const [isAuth, setIsAuth] = React.useState(false);
    const [exchApplied, setExchApplied] = React.useState(false);
    const accountState: any = useSelector((state: RootState) => state.account);
    const {
      exchange_filter: exchangeFilter = [],
      group = '',
      refresh_token: refToken = '',
      loggedIn,
      expires_at: expiresAt = 0,
    } = accountState;

    const dispatch = useDispatch();

    useEffect(() => {
      const intervalId = setInterval(() => {
        const timeDiff = new Date().getTime() - new Date(expiresAt * 1000).getTime();
        // If within 10 minutes of token expiration
        if (refToken && timeDiff >= -10 * 60 * 1000) {
          dispatch(refreshToken(refToken));
        } else if (timeDiff >= 0) {
          dispatch(logout());
        }
      }, refreshTokenInterval);

      return () => clearInterval(intervalId);
    }, [refToken]);

    useEffect(() => {
      const timeDiff = new Date().getTime() - new Date(expiresAt * 1000).getTime();
      // If within 10 minutes of token expiration
      if (refToken && timeDiff >= -10 * 60 * 1000) {
        dispatch(refreshToken(refToken));
      } else if (timeDiff >= 0) {
        dispatch(logout());
      }
    }, []);

    const { pathname } = router;

    const routeToDashboard = () => {
      const params = exchangeFilter.length > 0
        ? `?exchanges=${exchangeFilter}`
        : '';
      router.push(`/dashboard${params}`, '/dashboard');
    };

    const routeToSignIn = () => {
      router.push('/signin', '/signin');
    };

    const replaceWithExch = () => {
      router.replace(`${router.pathname}?exchanges=${exchangeFilter}`, router.pathname);
    };

    const hasExchanges = exchangeFilter.length > 0;

    useEffect(() => {
      if (loggedIn && authRoutes.includes(pathname)) {
        routeToDashboard();
      } else {
        setIsAuth(true);
      }

      if (hasExchanges) {
        if (!exchApplied) replaceWithExch();
        if (router.query?.exchanges) setExchApplied(true);
      }

      if (!loggedIn && tier2Routes.includes(pathname)) {
        routeToSignIn();
      }
    });

    if (!loggedIn && tier2Routes.includes(pathname)) {
      return (<div />);
    }

    if (loggedIn) {
      if ((!group || group === 'Tier0')
        || (group === 'Tier2' && !tier2Routes.includes(pathname))
        || (group === 'Tier1' && !tier1Routes.includes(pathname))
      ) {
        return (<UnauthView />);
      }
    }

    if ((isAuth && hasExchanges && exchApplied) || (isAuth && !hasExchanges)) {
      // eslint-disable-next-line react/jsx-props-no-spreading
      return (<Component {...props} group={group} />);
    }

    return (<div />);
  };

  return Auth;
};

export default withAuth;
