import React from 'react';

const ArrowDown: React.FC = () => (
  <svg
    width="23"
    height="79"
    viewBox="0 0 23 79"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.1766 77.8612C10.7643 78.445 11.7141 78.4419 12.2979 77.8542L21.8124 68.2769C22.3962 67.6892 22.3931 66.7394 21.8054 66.1556C21.2177 65.5717 20.268 65.5748 19.6841 66.1625L11.2268 74.6757L2.71363 66.2184C2.12592 65.6345 1.17617 65.6377 0.59232 66.2254C0.00846396 66.8131 0.011589 67.7628 0.599299 68.3467L10.1766 77.8612ZM9.48437 1.00491L9.73377 76.8019L12.7338 76.7921L12.4844 0.995037L9.48437 1.00491Z"
      fill="white"
    />
  </svg>
);

export default ArrowDown;
