import styled from 'styled-components';

interface TitleProps {
  textAlignMobile?: string;
  hideMobile?: boolean;
  marginMobile?: string;
  paddingMobile?: string;
  mobileBG?: string;
  margin?: string;
  fontWeight?: string;
  size?: string;
}

export const Title = styled.div<TitleProps>`
  font-size: ${({ size = '42px' }) => size};
  font-weight: ${({ theme, fontWeight }) => (fontWeight || theme.fontWeights.bold)};
  line-height: ${({ size = '42px' }) => size};
  margin: ${({ margin = '0 0 40px 0' }) => margin};
  z-index: 1;
  animation: leftToRight ease 1s;
  -webkit-animation: leftToRight ease 1s;
  -moz-animation: leftToRight ease 1s;
  -o-animation: leftToRight ease 1s;
  -ms-animation: leftToRight ease 1s;
  a {
    color: ${({ theme }) => theme.colors.accent};
  }
  @keyframes leftToRight {
    0% {
      margin-left: -35px;
    }
    100% {
      margin-left: 0;
    }
  }
  @media (max-width: 1000px) {
    width: 100%;
    font-size: 26px;
    line-height: 30px;
    text-align: ${({ textAlignMobile = 'left' }) => textAlignMobile};
    margin: ${({ marginMobile = '0' }) => marginMobile};
    padding: ${({ paddingMobile = '0 20px' }) => paddingMobile};
    display: ${({ hideMobile }) => (hideMobile ? 'none' : 'block')};
    background: ${({ mobileBG = 'none' }) => mobileBG};
  }
`;

export const SubTitle = styled.div`
  font-size: ${({ theme }) => theme.fontSizes[4]};
  font-weight: ${({ theme }) => theme.fontWeights.midBold};
  line-height: 56px;
  margin-bottom: 50px;
  z-index: 1;
  animation: rightToLeft ease 1s;
  -webkit-animation: rightToLeft ease 1s;
  -moz-animation: rightToLeft ease 1s;
  -o-animation: rightToLeft ease 1s;
  -ms-animation: rightToLeft ease 1s;
  @keyframes rightToLeft {
    0% {
      margin-left: 35px;
    }
    100% {
      margin-left: 0;
    }
  }
  @media (max-width: 1000px) {
    width: 100%;
    font-size: ${({ theme }) => theme.fontSizes[3]};
    line-height: ${({ theme }) => theme.fontSizes[3]};
    margin: 0;
    margin-top: 25px;
    margin-bottom: 25px;
    padding 0 20px;
  }
`;

export const SectionTitle = styled.div`
  font-size: ${({ theme }) => theme.fontSizes[3]};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  margin-bottom: 10px;
  @media (max-width: 1000px) {
    text-align: center;
  }
`;

export const BodyTextTopToBottom = styled.div`
  font-size: ${({ theme }) => theme.fontSizes[3]};
  text-align: left;
  overflow: hidden;
  animation: topToBottom ease 1s;
  -webkit-animation: topToBottom ease 1s;
  -moz-animation: topToBottom ease 1s;
  -o-animation: topToBottom ease 1s;
  -ms-animation: topToBottom ease 1s;
  max-width: 500px;
  @keyframes topToBottom {
    0% {
      margin-top: 25px;
    }
    100% {
      margin-top: 0;
    }
  }
  @media (max-width: 1000px) {
    span:nth-child(4), br:nth-child(5), br:nth-child(6),
    span:nth-child(7), br:nth-child(8), br:nth-child(9) {
      display: none;
    }
    margin: 20px 20px 0 20px;
  }
`;

interface FlexRowProps {
  flexDirection?: string;
  alignItems?: string;
  justifyContent?: string;
  accent?: boolean;
  reverseInMobile?: boolean;
  spaceInMobile?: boolean;
  justifyContentMobile?: string;
  margin?: string;
}

export const FlexRow = styled.div<FlexRowProps>`
  display: flex;
  flex-direction: ${({ flexDirection = 'row' }) => flexDirection};
  justify-content: center;
  align-items: ${({ alignItems = 'center' }) => alignItems};
  background: ${({ accent, theme }) => (accent ? theme.site.bannerBackground : '')};
  justify-content: ${({ justifyContent = 'center' }) => justifyContent};
  width: 100%;
  margin: ${({ margin = '0 0 150px 0' }) => margin};
  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s;
  -moz-animation: fadeIn ease 1s;
  -o-animation: fadeIn ease 1s;
  -ms-animation: fadeIn ease 1s;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @media (max-width: 1000px) {
    flex-direction: ${({ reverseInMobile, flexDirection }) => {
    if (reverseInMobile) return 'column-reverse';
    if (flexDirection) return 'flexDirection';
    return 'column';
  }};
    align-self: center;
    margin-bottom: ${({ spaceInMobile }) => (spaceInMobile ? '100px' : '20px')};
    justify-content: ${({ justifyContentMobile = 'center' }) => justifyContentMobile};
  }
`;

interface FlexOneProps {
  flex?: number;
  alignItems?: string;
  justifyContent?: string;
  alignSelf?: string;
  margin?: string;
  marginMobile?: string;
  hideMobile?: boolean;
  showMobile?: boolean;
  orderInMobile?: number;
}

export const FlexOne = styled.div<FlexOneProps>`
  display: ${({ showMobile }) => (showMobile ? 'none' : 'flex')};;
  position: relative;
  height: 100%;
  flex-direction: column;
  flex: ${({ flex = 1 }) => flex};;
  align-items: ${({ alignItems = 'center' }) => alignItems};
  justify-content: ${({ justifyContent = 'center' }) => justifyContent};
  align-self: ${({ alignSelf, alignItems = 'center' }) => (alignSelf || alignItems)};
  margin: ${({ margin = '0' }) => margin};
  @media (max-width: 1000px) {
    margin: ${({ marginMobile = '0' }) => marginMobile};
    padding: 0;
    flex: 1;
    align-items: center;
    width: 100%;
    display: ${({ hideMobile }) => (hideMobile ? 'none' : 'flex')};
    order: ${({ orderInMobile = '' }) => orderInMobile};
  }
`;

interface FlexTwoProps {
  alignItems?: string;
}

export const FlexTwo = styled.div<FlexTwoProps>`
  display: flex;
  flex-direction: column;
  flex: 2;
  justify-content: center;
  align-items: ${({ alignItems = 'center' }) => alignItems};
`;

export const ButtonTextToRight = styled.div`
  font-size: ${({ theme }) => theme.fontSizes[3]};
  display: flex;
  padding-right: 20px;
  padding-left: 20px;
  justify-content: flex-end;
  align-items: center;
  height: 50px;
  width: 350px;
  text-align: right;
  background: black;
  overflow: hidden;
  color: white;
  animation: slideIn ease 1.5s;
  -webkit-animation: slideIn ease 1.5s;
  -moz-animation: slideIn ease 1.5s;
  -o-animation: slideIn ease 1.5s;
  -ms-animation: slideIn ease 1.5s;
  @keyframes slideIn {
    0% {
      opacity: 0;
      width: 0px;
    }
    100% {
      opacity: 1;
      width: 300px;
    }
  }
`;

export const FlexWrap = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  justify-content: flex-end;
  align-items: flex-end;
`;

export const FeatureCard = styled.div`
  height: 300px;
  width: 300px;
  margin: 10px;
  color: black;
  @media (max-width: 1000px) {
    width: 100%;
    height: 150px;
  }
`;

interface FlexProps {
  flex?: number;
  margin?: string;
  hideMobile?: boolean;
  showFeatures?: boolean;
}

export const FlexDynamic = styled.div<FlexProps>`
  display: ${({ showFeatures }) => (showFeatures ? 'flex' : 'none')};
  flex: ${({ flex = 1 }) => flex};
  margin: ${({ margin = '0' }) => margin};
  @media (max-width: 1000px) {
    display: ${({ hideMobile }) => (hideMobile ? 'none' : 'flex')};
  }
`;

export const Content = styled.div`
  font-size: ${({ theme }) => theme.fontSizes[2]};
  background: #eeeeee;
  padding: 20px;
  overflow: hidden;
  height: 300px;
  width: 300px;
  box-shadow: 0 4px 10px -4px #616161;
  animation: slideIn ease 1s;
  -webkit-animation: expandIn ease 1s;
  -moz-animation: expandIn ease 1s;
  -o-animation: expandIn ease 1s;
  -ms-animation: expandIn ease 1s;
  @keyframes expandIn {
    0% {
      width: 0px;
    }
    100% {
      width: 300px;
    }
  }
  a {
    cursor: pointer;
    align-self: flex-end;
  }
  @media (max-width: 1000px) {
    width: 100%;
    height: 150px;
  }
`;

export const Accordion = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  border-radius: 20px;
  width: 100%;
  height: 500px;
  overflow: hidden;
  color: black;
  border: 2px solid ${(props) => props.theme.site.bannerBorder};
  margin: 50px 100px;
  @media (max-width: 1350px) {
    flex-direction: column;
    height: auto;
    margin: 0;
  }
`;

interface AccordionCardProps {
  background: string;
  active: boolean;
}

export const AccordionCard = styled.div<AccordionCardProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex: ${({ active }) => (active ? 1 : 0)};
  height: 500px;
  transition: all 400ms;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  margin-left: -20px;
  background: ${({ background }) => background};
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: -20px;
  }
  @media (max-width: 1350px) {
    flex-direction: column;
    margin-left: 0;
    margin-top: -20px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 0;
    width: 100%;
    height: auto;
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: -20px;
    }
  }
`;

export const AccordionLabel = styled.div`
  font-size: ${({ theme }) => theme.fontSizes[3]};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  cursor: pointer;
  text-align: center;
  padding: 0 20px 0 40px;
  height: 500px;
  transition: all 400ms;
  transition: transform 0ms;
  & > span {
    transform: rotate(90deg);
    padding: 20px 0 20px 0;
  }
  & > div {
    flex: 1;
  }
  @media (max-width: 1350px) {
    flex-direction: row;
    justify-content: space-between;
    writing-mode: horizontal-tb;
    transform: rotate(0deg);
    padding: 10px 10px 0 10px;
    height: 100px;
    width: 100%;
    text-align: left;
    & > span {
      transform: rotate(0deg);
      padding: 0;
    }
    & > div {
      max-width: 100px;
    }
  }
`;

interface AccordionContentProps {
  active: boolean;
}

export const AccordionContent = styled.div<AccordionContentProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: ${({ active }) => (active ? '100%' : '0')};
  transition: width 200ms;
  @media (max-width: 1350px) {
    width: 100%;
    height: ${({ active }) => (active ? '400px' : '0')};
    transition: width 0ms;
    transition: height 200ms;
  }
`;

export const CardWithShadow = styled.div`
  border-radius: 10px;
  box-shadow: 10px 0 20px #e6e6e6;
  margin: 0 20px 20px 20px;
  video {
    border-radius:  10px;
  }
`;

export const NumberBlock = styled.div`
  width: 75px;
  height: 75px;
  font-size: 32px;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  background: ${({ theme }) => theme.colors.accent};
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
`;

interface BodyTextProps {
  hideMobile?: boolean;
  textAlignMobile?: string;
  paddingMobile?: string;
}

export const BodyText = styled.p<BodyTextProps>`
  font-size: ${({ theme }) => theme.fontSizes[4]};
  text-align: left;
  @media (max-width: 1000px) {
    font-size: ${({ theme }) => theme.fontSizes[2]};
    display: ${({ hideMobile }) => (hideMobile ? 'none' : 'block')};
    text-align: ${({ textAlignMobile = 'center' }) => textAlignMobile};
    padding: ${({ paddingMobile = '0' }) => paddingMobile};
  }
`;

export const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  box-shadow: 0 4px 10px -4px #61616190;
  transition: all 1000ms;
`;

interface QuadWrapperProps {
  showInMobile?: boolean;
}

export const QuadWrapper = styled.div<QuadWrapperProps>`
  display: ${({ showInMobile }) => (showInMobile ? 'none' : 'block')};
  overflow: none;
  animation: fadeIn ease 1000ms;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @media (max-width: 1000px) {
    width: 100%;
    display: block;
  }
`;

export const FlexRowTwo = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px 0;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  animation: fadeIn ease 100ms;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @media (max-width: 1000px) {
    flex-direction: column;
    margin: 0;
  }
`;

export const Card = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 10px 0 10px;
  box-shadow: 0 4px 10px -4px #61616190;
  width: 200px;
  height: 100px;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  background: white;
  animation: topLeftToCenter ease 1000ms;
  @keyframes topLeftToCenter {
    0% {
      margin: 0 50px 40px 10px;
    }
    100% {
      margin: 0 10px 0 10px;
    }
  }
  @media (max-width: 1000px) {
    text-align: center;
    width: 150px;
    height: 75px;
  }
`;

export const AccordionText = styled.div`
  width: 400px;
  @media (max-width: 1000px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 10px 50px 10px;
  }
`;

export const Anchor = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: ${({ theme }) => theme.colors.accent};
  font-size: ${({ theme }) => theme.fontSizes[3]};
  font-weight: bold;
  :hover {
    opacity: 0.5;
  }
  @media (max-width: 1000px) {
    width: 100%;
    text-align: center;
    align-self: center;
  }
`;

interface VideoProps {
  height?: string;
  width?: string;
}

export const Video = styled.video<VideoProps>`
  width: ${({ width = '560px' }) => width};
  height: ${({ height = '315px' }) => height};
  border-radius: 8px;
  object-fit: cover;
  -webkit-box-shadow: 1px 1px 15px #00000080;
  box-shadow: 1px 1px 15px #00000080;
`;

export const FullScreen = styled.div`
  z-index: 999999999;
  position: fixed;
  top: 0;
  background: rgba(0,0,0,0.5);
  height: 100%;
  width: 100%;
  padding: 80px 100px 80px 100px;
  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0;
  }
`;

export const Button = styled.div`
  white-space: nowrap;
  overflow: hidden;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.accent};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes[3]};
  padding: 10px;
  color: white;
  width: 250px;
  text-align: center;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-left: 20px;
  margin-bottom: 20px;
  &:hover {
    opacity: 0.8;
  }
  animation: buttonSlideIn ease 1.5s;
  -webkit-animation: buttonSlideIn ease 1.5s;
  -moz-animation: buttonSlideIn ease 1.5s;
  -o-animation: buttonSlideIn ease 1.5s;
  -ms-animation: buttonSlideIn ease 1.5s;
  @keyframes buttonSlideIn {
    0% {
      opacity: 0;
      width: 0px;
    }
    100% {
      opacity: 1;
      width: 250px;
    }
  }
  a {
    color: white;
  }
  @media (max-width: 1000px) {
    font-size: ${({ theme }) => theme.fontSizes[2]};
    padding: 5px;
    margin-right: 0;
    margin-left: 0;
    width: 90%;
    animation: buttonSlideIn ease 1.5s;
    -webkit-animation: buttonSlideIn ease 1.5s;
    -moz-animation: buttonSlideIn ease 1.5s;
    -o-animation: buttonSlideIn ease 1.5s;
    -ms-animation: buttonSlideIn ease 1.5s;
    @keyframes buttonSlideIn {
      0% {
        opacity: 0;
        width: 0px;
      }
      100% {
        opacity: 1;
        width: 90%;
      }
    }
  }
`;

export const ButtonTwo = styled.div`
  white-space: nowrap;
  overflow: hidden;
  border-radius: 8px;
  border: 2px solid ${({ theme }) => theme.site.bannerBorder};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes[3]};
  padding: 10px;
  width: 200px;
  text-align: center;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 20px;
  &:hover {
    opacity: 0.8;
  }
  animation: slideIn ease 1.5s;
  -webkit-animation: slideIn ease 1.5s;
  -moz-animation: slideIn ease 1.5s;
  -o-animation: slideIn ease 1.5s;
  -ms-animation: slideIn ease 1.5s;
  @keyframes slideIn {
    0% {
      opacity: 0;
      width: 0px;
    }
    100% {
      opacity: 1;
      width: 200px;
    }
  }
  a {
    color: black;
  }
  @media (max-width: 1000px) {
    font-size: ${({ theme }) => theme.fontSizes[2]};
    padding: 5px;
    margin-right: 0;
    width: 150px;
    animation: slideInMb ease 1.5s;
    -webkit-animation: slideInMb ease 1.5s;
    -moz-animation: slideInMb ease 1.5s;
    -o-animation: slideInMb ease 1.5s;
    -ms-animation: slideInMb ease 1.5s;
    @keyframes slideInMb {
      0% {
        opacity: 0;
        width: 0px;
      }
      100% {
        opacity: 1;
        width: 150px;
      }
    }
  }
`;

export const ButtonThree = styled.div`
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  border-radius: 8px;
  background: black;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes[2]};
  padding: 10px;
  width: 250px;
  text-align: center;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 20px;
  color: white;
  &:hover {
    opacity: 0.8;
  }
  animation: slideIn ease 1.5s;
  -webkit-animation: slideIn ease 1.5s;
  -moz-animation: slideIn ease 1.5s;
  -o-animation: slideIn ease 1.5s;
  -ms-animation: slideIn ease 1.5s;
  @keyframes slideIn {
    0% {
      opacity: 0;
      width: 0px;
    }
    100% {
      opacity: 1;
      width: 250px;
    }
  }
  a {
    color: black;
  }
  @media (max-width: 1000px) {
    font-size: ${({ theme }) => theme.fontSizes[2]};
    padding: 5px;
    margin-right: 0;
    width: 90%;
    animation: slideInMobile ease 1.5s;
    -webkit-animation: slideInMobile ease 1.5s;
    -moz-animation: slideInMobile ease 1.5s;
    -o-animation: slideInMobile ease 1.5s;
    -ms-animation: slideInMobile ease 1.5s;
    @keyframes slideInMobile {
      0% {
        opacity: 0;
        width: 0px;
      }
      100% {
        opacity: 1;
        width: 90%;
      }
    }
  }
`;

export const ExternalLink = styled.a`
  white-space: nowrap;
  overflow: hidden;
  border-radius: 8px;
  border: 2px solid ${({ theme }) => theme.site.bannerBorder};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes[2]};
  padding: 10px;
  width: 250px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  color: black;
  &:hover {
    opacity: 0.8;
  }
  animation: linkSlideIn ease 1.5s;
  -webkit-animation: linkSlideIn ease 1.5s;
  -moz-animation: linkSlideIn ease 1.5s;
  -o-animation: linkSlideIn ease 1.5s;
  -ms-animation: linkSlideIn ease 1.5s;
  @keyframes linkSlideIn {
    0% {
      opacity: 0;
      width: 0px;
    }
    100% {
      opacity: 1;
      width: 250px;
    }
  }
  a {
    color: black;
  }
  @media (max-width: 1000px) {
    font-size: ${({ theme }) => theme.fontSizes[2]};
    padding: 5px;
    margin-right: 0;
    width: 90%;
    animation: slideInMobile ease 1.5s;
    -webkit-animation: slideInMobile ease 1.5s;
    -moz-animation: slideInMobile ease 1.5s;
    -o-animation: slideInMobile ease 1.5s;
    -ms-animation: slideInMobile ease 1.5s;
    @keyframes slideInMobile {
      0% {
        opacity: 0;
        width: 0px;
      }
      100% {
        opacity: 1;
        width: 90%;
      }
    }
  }
`;

export const SmallButtonTwo = styled.div`
  white-space: nowrap;
  overflow: hidden;
  border-radius: 8px;
  border: 2px solid ${({ theme }) => theme.site.bannerBorder};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes[2]};
  padding: 5px;
  width: 200px;
  text-align: center;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 60px;
  margin-top: 25px;
  &:hover {
    opacity: 0.8;
  }
  animation: slideIn ease 1.5s;
  -webkit-animation: slideIn ease 1.5s;
  -moz-animation: slideIn ease 1.5s;
  -o-animation: slideIn ease 1.5s;
  -ms-animation: slideIn ease 1.5s;
  @keyframes slideIn {
    0% {
      opacity: 0;
      width: 0px;
    }
    100% {
      opacity: 1;
      width: 200px;
    }
  }
  a {
    color: black;
  }
  @media (max-width: 1000px) {
    font-size: ${({ theme }) => theme.fontSizes[2]};
    width: 90%;
    padding: 5px;
    margin-top: 0;
    animation: slideInMobile ease 1.5s;
    -webkit-animation: slideInMobile ease 1.5s;
    -moz-animation: slideInMobile ease 1.5s;
    -o-animation: slideInMobile ease 1.5s;
    -ms-animation: slideInMobile ease 1.5s;
    @keyframes slideInMobile {
      0% {
        opacity: 0;
        width: 0px;
      }
      100% {
        opacity: 1;
        width: 90%;
      }
    }
  }
`;
