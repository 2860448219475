/* eslint-disable react/prop-types */
import React from 'react';
import styled, { keyframes } from 'styled-components';
import Link from 'next/link';
import { PageContainer } from '../../../ui';
import ArrowDown from './ArrowDown';

const textReveal = keyframes`
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const HomeItemContainer = styled(PageContainer)`
  position: relative;
  z-index: 1;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: space-around;
  background-color: #051025;
`;

const HomeItemWrapper = styled.div`
  padding-top: 8vh;
  display: flex;
  justify-content: center;
  height: 70vh;
  
  @media (max-width: 1200px) {
    flex-direction: column;
    padding-top: 20vh;
  }
`;

const Title = styled.h1`
  font-style: normal;
  font-weight: 600;
  line-height: 50px;
  font-size: 40px;
  text-align: center;
  color: #ffffff;
  opacity: 0;
  transform: translateY(100%);
  animation: ${textReveal} 1s ease-out forwards;
  
  @media (max-width: 1000px) {
    text-align: left;
    font-size: 26px;
  }
`;

const SubTitle = styled.h2`
  font-style: normal;
  font-weight: 500;
  font-size: 23px;
  text-align: center;
  color: #ffffff;
  opacity: 0;
  transform: translateY(100%);
  animation: ${textReveal} 1s ease-out forwards 0.5s;

  @media (max-width: 1000px) {
    text-align: left;
    font-size: 18px;
  }
`;

const TextBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 1200px) {
    margin-bottom: 50px;
  }
`;

const ImageBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  animation: image-reveal 1.5s ease-out;
  
  img {
    width: 85%;
  }
  
  @keyframes image-reveal {
    from {
      transform: translateY(40%);
      opacity: 0;
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @media (max-width: 1200px) {
    img {
      width: 50%
    }
  }

  @media (max-width: 768px) {
    img {
      width: 75%
    }
  }
`;

const LinksWrapper = styled.div`
  opacity: 0;
  animation: links-reveal 1s ease-out forwards 1s;

  @keyframes links-reveal {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 35px;
  @media(max-width: 1200px){
    margin-bottom: 0;
    margin-top: 25px;
  }
`;

const Button = styled.button`
  background: #55b1ec;
  border-radius: 5px;
  outline: none;
  font-weight: 700;
  font-size: 24px;
  color: white;
  border: none;
  padding: 13px 26px 13px 41px;
  cursor: pointer;
`;

const ArrowWrapper = styled.div`
  cursor: pointer;

  @media (max-width: 860px) {
    margin-top: 20px;
  }
`;

export type BaseHomeItemProps = {
  key: string;
  title: string;
  subtitle: string;
  image: string;
}

type HomeItemProps =
  BaseHomeItemProps & ({ buttonText: string; path: string } | { onArrowClick: () => void });

const HomeItem: React.FC<HomeItemProps> = ({
  title,
  subtitle,
  image,
  ...props
}) => (
  <HomeItemContainer>
    <HomeItemWrapper>
      <TextBlock>
        <Title>{title}</Title>
        <SubTitle>{subtitle}</SubTitle>
      </TextBlock>
      <ImageBlock>
        <img src={image} alt="preview" />
      </ImageBlock>
    </HomeItemWrapper>
    <LinksWrapper>
      {'path' in props ? (
        <ButtonWrapper>
          <Link href={props.path}>
            <Button>{props.buttonText}</Button>
          </Link>
        </ButtonWrapper>
      ) : (
        <ArrowWrapper onClick={props.onArrowClick}>
          <ArrowDown />
        </ArrowWrapper>
      )}
    </LinksWrapper>
  </HomeItemContainer>
);

export default HomeItem;
