/* eslint-disable no-param-reassign */
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Flex, PageContainer } from '../../../ui';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  color: white;
  margin-top: 100px;
  @media (max-width: 1024px) {
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
`;
const VerticalLine = styled.div`
  width: 1px;
  height: 100%;
  background-color: white;
  opacity: 0.5;
  margin-right: 100px;
  margin-left: 100px;
`;
const FormContainer = styled.div`
  width: 100%;
`;

const TopText = styled.div`
  border: 2px solid rgba(255, 255, 255, 0.5);
  display: flex;
  width: fit-content;
  padding: 4px 25px;
`;

const LeftBlockWrapper = styled.div`
  width: 50%;
  padding-left: 200px;

  @media (min-width: 320px) and (max-width: 860px) {
    width: 100%;
    padding-left: 0;
  }

  @media (min-width: 861px) and (max-width: 1024px) {
    width: 100%;
    padding-left: 200px;
  }
`;

const RightBlockWrapper = styled.div`
  width: 50%;

  @media (min-width: 320px) and (max-width: 860px) {
    width: 100%;
    padding-left: 0;
  }

  @media (min-width: 861px) and (max-width: 1024px) {
    width: 100%;
    padding-left: 200px;
  }
`;

const MeetingWrapper = styled.div`
  iframe {
    min-width: 0 !important;
  }
`;

declare const window: any;

const ContactForms: React.FC = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js';
    script.type = 'text/javascript';
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = '//js.hsforms.net/forms/embed/v2.js';
    script.type = 'text/javascript';
    document.body.appendChild(script);

    script.onload = () => {
      window.hbspt.forms.create({
        region: 'na1',
        portalId: '24482321',
        formId: 'e4d6444f-fdda-4eba-9ec0-f4f1c0ee824a',
        target: '#container',
      });
      return () => {
        document.body.removeChild(script);
      };
    };
  }, []);

  return (
    <Flex style={{ backgroundColor: '#051025' }}>
      <PageContainer>
        <Container>
          <LeftBlockWrapper>
            <TopText>
              <h1>Send us a note</h1>
            </TopText>
            <FormContainer id="container" />
          </LeftBlockWrapper>
          <div>
            <VerticalLine />
          </div>
          <RightBlockWrapper>
            <TopText>
              <h1>Schedule a demo</h1>
            </TopText>
            <MeetingWrapper
              className="meetings-iframe-container"
              data-src="https://calendar.uptick.co/meetings/uptick/demo-request?embed=true"
            />
          </RightBlockWrapper>
        </Container>
      </PageContainer>
    </Flex>
  );
};

export default ContactForms;
