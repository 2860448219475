import { GetServerSideProps } from 'next';
import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';
import withAuth from '../src/withAuth';
import { getMetaTags, getMetaUrl } from '../src/utils';
// eslint-disable-next-line import/no-named-default
import { default as AuthClient } from '../src/utils/api/auth/clients';

import Home from '../src/home/components/Home';
import rootReducer from '../src/store/reducers';

let composeEnhancers = compose;
if (process.env.REACT_APP_ENV === 'dev') {
  const composeWithDevToolsExtension = (window as any)
    .__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;

  if (typeof composeWithDevToolsExtension === 'function') {
    composeEnhancers = composeWithDevToolsExtension;
  }
}

const sagaMiddleware = createSagaMiddleware();

export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk, sagaMiddleware)),
);

const authClient = new AuthClient();

export const getServerSideProps: GetServerSideProps = async () => {
  const title = 'Uptick';
  const description = 'Actionable Intelligence for the Cryptocurrency Markets and Portfolio Management';

  const metaUrl = getMetaUrl('');
  const metaTags = getMetaTags(title, description, metaUrl);

  const oauthLogoutUrl = authClient.getOauthLogoutUrl(process.env.APP_URL ?? '');

  return {
    props: {
      title,
      metaTags,
      oauthLogoutUrl,
    },
  };
};

export default withAuth(Home);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
