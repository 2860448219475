/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import Head from 'next/head';
import styled from 'styled-components';
import { useSwipeable } from 'react-swipeable';
import Header from '../Header';
import Intro from './Intro';
import HomeItem from './HomeItem';
import SideMenu from '../SideMenu';
import ContactForms from '../Contact/ContactForms';
import NewFooter from '../Footer/NewFooter';

interface HomeProps {
  title?: string;
  metaTags?: any[];
}

const Container = styled.div<{ canScroll: boolean; }>`
  height: 100vh;
  overflow-y: ${({ canScroll }) => (canScroll ? 'scroll' : 'hidden')};
  background-color: #051025;
`;

const items = [
  <Intro />,
  <HomeItem
    key="02 — PRISM"
    title="Portfolio Risk Intelligence and Strategy Management"
    subtitle="The most advanced portfolio construction, optimization and risk management application"
    buttonText="View PRISM"
    image="./assets/home/prism/prism-1.png"
    path="/prism"
  />,
  <HomeItem
    key="03 — RADAR"
    title="Real-Time Analytics for Digital Assets Research"
    subtitle="Elevate your investment strategy with real-time analytics, accelerating idea generation and deepening market insights"
    buttonText="View RADAR"
    image="./assets/home/radar/radar-1.png"
    path="/radar"
  />,
  <HomeItem
    key="04 — ADAPT"
    title="APIs for Digital Assets Proprietary Trading"
    subtitle="Empower your trading strategies with data-driven investment strategies, and unmatched data precision"
    buttonText="View ADAPT"
    image="./assets/home/adapt/adapt-1.png"
    path="/adapt"
  />,
  <>
    <ContactForms />
    <NewFooter />
  </>,
];

const Home: React.FC<HomeProps> = ({ metaTags, title }) => {
  const [currentScreen, setCurrentScreen] = useState(1);
  const [isScrollEnabled, setIsScrollEnabled] = useState(true);

  const scrollUp = () => {
    if (!isScrollEnabled) return;
    if (currentScreen === 1) return;
    if (currentScreen === items.length && document.body.scrollTop !== 0) return;

    setIsScrollEnabled(false);
    setCurrentScreen(currentScreen - 1);

    setTimeout(() => setIsScrollEnabled(true), 1500);
  };

  const scrollDown = () => {
    if (!isScrollEnabled) return;
    if (currentScreen === items.length) return;

    setIsScrollEnabled(false);
    setCurrentScreen(currentScreen + 1);

    setTimeout(() => setIsScrollEnabled(true), 1500);
  };

  const swipeHandlers = useSwipeable({ onSwipedUp: scrollDown, onSwipedDown: scrollUp });

  useEffect(() => {
    const handleScroll = (event: WheelEvent) => (event.deltaY > 0 ? scrollDown() : scrollUp());
    window.addEventListener('wheel', handleScroll);
    return () => window.removeEventListener('wheel', handleScroll);
  }, [currentScreen, isScrollEnabled]);

  return (
    <>
      <Head>
        <title>{title}</title>
        {metaTags && metaTags.map((item) => (
          <meta
            name={item.name}
            property={item.name}
            key={item.key}
            content={item.content}
          />
        ))}
      </Head>
      <Container {...swipeHandlers} canScroll={currentScreen === items.length}>
        <Header onClick={() => setCurrentScreen(1)} />
        <SideMenu
          items={[
            { title: '01 — INTRO', onClick: () => setCurrentScreen(1) },
            { title: '02 — PRISM', onClick: () => setCurrentScreen(2) },
            { title: '03 — RADAR', onClick: () => setCurrentScreen(3) },
            { title: '04 — ADAPT', onClick: () => setCurrentScreen(4) },
            { title: '05 — CONTACT', onClick: () => setCurrentScreen(5) },
          ]}
          activeItem={currentScreen - 1}
        />
        {items[currentScreen - 1]}
      </Container>
    </>
  );
};

export default Home;
