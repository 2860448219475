/* eslint-disable react/prop-types */
import React from 'react';
import styled from 'styled-components';

const SidebarContent = styled.div`
  height: 100vh;
  position: fixed;
  top: 0;
  left: 35px;
  z-index: 2;
  display: flex;
  justify-content: flex-end;
  padding-bottom: 68px;
  flex-direction: column;
  text-align: center;
  font-weight: 700;
  
  @media (max-width: 860px) {
    display: none;
  }
`;

const SidebarItem = styled.div<{ active: boolean }>`
  padding: 5px 10px;
  cursor: pointer;
  border-radius: ${({ active }) => (active ? '30px' : '0')};
  border: 2px solid ${({ active }) => (active ? '#FFFFFF' : 'transparent')};
  color: #FFFFFF;
  opacity: ${({ active }) => (active ? '100%' : '60%')};
`;

type SideMenuItem = {
  title: string;
  onClick: () => void;
};

type SideMenuProps = {
  items: SideMenuItem[];
  activeItem: number;
};

const SideMenu: React.FC<SideMenuProps> = ({ items, activeItem }) => (
  <SidebarContent>
    {items.map(({ title, onClick }, index) => (
      <SidebarItem
        key={title}
        active={index === activeItem}
        onClick={onClick}
      >
        {title}
      </SidebarItem>
    ))}
  </SidebarContent>
);

export default SideMenu;
